import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  EntityLoadingStatus,
} from '@backstage/plugin-catalog-react';
import useAsyncRetry from 'react-use/esm/useAsyncRetry';
import { identityApiRef } from '@backstage/core-plugin-api';
import { Entity, EntityRelation } from '@backstage/catalog-model';

function getGroupRefForUserEntity(userEntity: Entity) {
  const excludedGroups = ['moonpig-dev', 'moonpig-ops'];

  // Get all groups for the user
  const groups = userEntity?.relations?.filter(r => r.type === 'memberOf');

  // Find the first group that is not excluded or a codeowners group
  const teamGroup: EntityRelation =
    groups?.find(g => {
      const groupName = g.targetRef.split('/').pop() ?? '';
      return (
        !excludedGroups.includes(groupName) && !groupName.includes('codeowners')
      );
    }) ?? ({} as EntityRelation);

  return teamGroup.targetRef;
}

/**
 * Returns the group entity associated with the current user
 * @returns {EntityLoadingStatus}
 */
export const useGroupEntityFromUser = (): EntityLoadingStatus => {
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const {
    value: entity,
    error,
    loading,
    retry: refresh,
  } = useAsyncRetry(async () => {
    // Get the identity of the currently logged in user
    const identity = await identityApi.getBackstageIdentity();

    // Get the catalog entity of the currently logged in user
    const userEntity = await catalogApi.getEntityByRef(identity?.userEntityRef);
    if (!userEntity) {
      throw new Error('User entity not found in catalog');
    }

    // Determine which group to show
    const groupRef = getGroupRefForUserEntity(userEntity);
    if (!groupRef) {
      throw new Error('No group found for user');
    }

    // Get the group
    const group = await catalogApi.getEntityByRef(groupRef);
    if (!group) {
      throw new Error('Group entity not found in catalog');
    }

    return group;
  }, [identityApi, catalogApi]);

  return { entity, loading, error, refresh };
};
