import React from "react";
import { TableColumn } from "@backstage/core-components";
import { CatalogTable, CatalogTableRow } from "@backstage/plugin-catalog";
import { EntityListContextProps } from "@backstage/plugin-catalog-react";
import {
  GitHubIconLink,
  GrafanaIconLink,
  OpenSearchIconLink,
  PagerDutyIconLink,
} from '@internal/backstage-plugin-catalog-icon-links';

const createGitHubColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: 'GitHub',
    field: 'entity.metadata.annotations',
    type: 'string',
    hidden: false,
    searchable: true,
    resizable: true,
    sorting: false,
    align: 'center',
    cellStyle: {
      padding: '0px 5px',
    },
    width: 'auto',
    render: ({ entity }) => <GitHubIconLink entity={entity} />,
  };
}

const createObservabilityColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: 'Observability',
    field: 'entity.metadata.annotations',
    type: 'string',
    hidden: false,
    searchable: true,
    resizable: true,
    sorting: false,
    align: 'left',
    cellStyle: {
      'padding': '0px 5px',
    },
    width: 'auto',
    render: ({ entity }) => {
      return (
        <>
          <GrafanaIconLink entity={entity} />
          <OpenSearchIconLink entity={entity} />
          <PagerDutyIconLink entity={entity} />
        </>
      );
    }
  }
}

export const customCatalogTableColumns = (entityListContext: EntityListContextProps): TableColumn<CatalogTableRow>[] => {
  const columns = CatalogTable.defaultColumnsFunc(entityListContext);
  const kindFilter = entityListContext.filters.kind?.value;
  if (kindFilter === 'system') {
    columns.push(createGitHubColumn());
    columns.push(createObservabilityColumn());
  }
  if (kindFilter === 'component') {
    columns.push(createObservabilityColumn());
  }
  return columns;
}
