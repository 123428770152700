import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
} from '@backstage/theme';

import { alpha } from '@material-ui/core/styles';
import { theme as mc } from '@moonpig/launchpad-theme-mission-control'

const pageThemesOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: mc.palette.colorTextHeadline,
    backgroundImage: "unset"
  };
});

export const moonpigTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: mc.palette.colorPrimary02,
        light: mc.palette.colorPrimary02,
        dark: mc.palette.colorBackground07,
      },
      secondary: {
        main: mc.palette.colorPrimary01,
        light: mc.palette.colorPrimary01,
        dark: mc.palette.colorBackground05,
      },
      grey: {
        50: mc.palette.colorBlack10,
        100: mc.palette.colorBlack10,
        200: mc.palette.colorBlack20,
        300: mc.palette.colorBlack30,
        400: mc.palette.colorBlack40,
        500: mc.palette.colorBlack50,
        600: mc.palette.colorBlack60,
        700: mc.palette.colorBlack70,
        800: mc.palette.colorBlack80,
        900: mc.palette.colorBlack90,
      },
      error: {
        main: mc.palette.colorFeedbackError,
        light: mc.palette.colorBackgroundError,
        dark: mc.palette.colorFeedbackError,
      },
      warning: {
        main: mc.palette.colorFeedbackWarning,
        light: mc.palette.colorBackgroundWarning,
        dark: mc.palette.colorFeedbackWarning,
      },
      success: {
        main: mc.palette.colorFeedbackSuccess,
        light: mc.palette.colorBackgroundSuccess,
        dark: mc.palette.colorFeedbackSuccess,
      },
      info: {
        main: mc.palette.colorFeedbackInformation,
        light: mc.palette.colorBackgroundInformation,
        dark: mc.palette.colorFeedbackInformation,
      },
      navigation: {
        ...palettes.light.navigation,
        background: mc.palette.colorInteractionIcon,
        color: mc.palette.colorTextInverted,
        indicator: mc.palette.colorInteractionFocusIndicator,
        navItem: {
          hoverBackground: mc.palette.colorInteractionButtonHover,
        },
      },
      text: {
        primary: mc.palette.colorTextHeadline,
      },
      background: {
        default: mc.palette.colorBackground01,
      },
      textSubtle: mc.palette.colorTextBody,
    },
  }),
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Moonpig, sans-serif',
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  pageTheme: pageThemesOverride,
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          boxShadow: 'unset',
          paddingBottom: theme.spacing(1),
        }),
        title: ({ theme }) => ({
          color: theme.page.fontColor,
          fontWeight: 900,
        }),
        subtitle: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
        type: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
        breadcrumb: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        })
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
        },
      },
    },
    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: mc.palette.colorBackground01,
          },
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderBottom: `1px solid ${mc.palette.colorBlack20}`,
            boxShadow: 'none',
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important', // lowercase letters
          },
        },
      },
    },
    CatalogReactUserListPicker: {
      styleOverrides: {
        title: {
          textTransform: 'none', // lowercase letters
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: mc.palette.colorInteractionIcon,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        standardError: {
          color: mc.palette.colorFeedbackError,
          backgroundColor: mc.palette.colorBackgroundError,
          '& $icon': {
            color: mc.palette.colorFeedbackError,
          },
        },
        standardInfo: {
          color: mc.palette.colorFeedbackInformation,
          backgroundColor: mc.palette.colorBackgroundInformation,
          '& $icon': {
            color: mc.palette.colorFeedbackInformation,
          },
        },
        standardSuccess: {
          color: mc.palette.colorFeedbackSuccess,
          backgroundColor: mc.palette.colorBackgroundSuccess,
          '& $icon': {
            color: mc.palette.colorFeedbackSuccess,
          },
        },
        standardWarning: {
          color: mc.palette.colorFeedbackWarning,
          backgroundColor: mc.palette.colorBackgroundWarning,
          '& $icon': {
            color: mc.palette.colorFeedbackWarning,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: 'none', // lowercase letters
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 3,
          backgroundColor: theme.palette.grey[50],
          margin: 3,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: mc.palette.colorBlack00,
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none', // lowercase letters
        },
      },
    },
  },
});