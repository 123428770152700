import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
  shapes,
  genPageTheme,
} from '@backstage/theme';
import { theme as mc } from '@moonpig/launchpad-theme-mission-control'
import { moonpigPink } from './colours';

const pageThemesOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesOverride[key] = {
    ...defaultPageThemes[key],
    ...genPageTheme({
      colors: [mc.palette.colorBackground05],
      shape: shapes.wave,
    })
  };
});

export const moonpigDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: moonpigPink,
      },
      secondary: {
        main: mc.palette.colorPrimary02,
      },
      navigation: {
        ...palettes.dark.navigation,
        indicator: mc.palette.colorInteractionFocusIndicator,
      },
    },
  }),
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Moonpig, sans-serif',
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  pageTheme: pageThemesOverride,
  defaultPageTheme: 'home',
  components: {
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderBottom: `1px solid ${mc.palette.colorBlack20}`,
            boxShadow: 'none',
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important', // lowercase letters
          },
        },
      },
    },
    CatalogReactUserListPicker: {
      styleOverrides: {
        title: {
          textTransform: 'none', // lowercase letters
        },
      },
    },
    PluginCatalogEntityLinksEmptyState: {
      styleOverrides: {
        code: {
          backgroundColor: mc.palette.colorBlack90,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          borderColor: mc.palette.colorBlack100,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: moonpigPink,
          '& path': {
            fill: moonpigPink,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: 'none', // lowercase letters
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none', // lowercase letters
        },
      },
    },
  },
});