import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

export const catalogIconLinksPlugin = createPlugin({
  id: 'catalog-icon-links',
});

export const GitHubIconLink = catalogIconLinksPlugin.provide(
  createComponentExtension({
    name: 'GitHubIconLink',
    component: {
      lazy: () => import('./components/IconLinks').then(m => m.GitHubIconLink),
    },
  }),
);

export const GrafanaIconLink = catalogIconLinksPlugin.provide(
  createComponentExtension({
    name: 'GrafanaIconLink',
    component: {
      lazy: () => import('./components/IconLinks').then(m => m.GrafanaIconLink),
    },
  }),
);

export const PagerDutyIconLink = catalogIconLinksPlugin.provide(
  createComponentExtension({
    name: 'PagerDutyIconLink',
    component: {
      lazy: () => import('./components/IconLinks').then(m => m.PagerDutyIconLink),
    },
  }),
);

export const OpenSearchIconLink = catalogIconLinksPlugin.provide(
  createComponentExtension({
    name: 'OpenSearchIconLink',
    component: {
      lazy: () => import('./components/IconLinks').then(m => m.OpenSearchIconLink),
    },
  }),
);

