import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
  analyticsApiRef,
} from '@backstage/core-plugin-api';
import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { MoonpigTechRadarApi } from './apis/techRadar';
import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) =>
      new MoonpigTechRadarApi({
        discovery: discoveryApi,
        identity: identityApi,
      }),
  }),
  // GA4 Analytics API Implementation.
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
        userIdTransform: async (userEntityRef: string): Promise<string> => {
          return userEntityRef;
        },
      }),
  }),
  ScmAuth.createDefaultApiFactory(),
];
