import {
  TechRadarApi
} from '@backstage-community/plugin-tech-radar';
import { TechRadarLoaderResponse } from '@backstage-community/plugin-tech-radar-common';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export class MoonpigTechRadarApi implements TechRadarApi {
  private discoveryApi: DiscoveryApi;
  private identityApi: IdentityApi;

  constructor(options: { discovery: DiscoveryApi; identity: IdentityApi }) {
    this.discoveryApi = options.discovery;
    this.identityApi = options.identity;
  }

  private mapToTechRadarLoaderResponse(data: any): TechRadarLoaderResponse {
    return {
      ...data,
      entries: data.entries.map((entry: { timeline: any[] }) => ({
        ...entry,
        timeline: entry.timeline.map(timeline => ({
          ...timeline,
          date: new Date(timeline.date),
        })),
      })),
    };
  }

  async load(): Promise<TechRadarLoaderResponse> {
    const url = await this.discoveryApi.getBaseUrl('techradar');
    const { token: idToken } = await this.identityApi.getCredentials();

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
      },
    });

    if (!response.ok) {
      return {} as TechRadarLoaderResponse;
    }

    return this.mapToTechRadarLoaderResponse(await response.json());
  }
}
