import React from 'react';
import {
    Content,
    Header,
    Page,
    SupportButton,
} from '@backstage/core-components';
import {  makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    content: {
        width: '100%',
        height: '100%',
        padding: 0,
    },
    iframe: {
        width: '100%',
        height: '100%',
        allow: 'fullscreen; clipboard-read; clipboard-write',
        allowFullScreen: true,
        frameborder: 0,
    }
}));

export interface MoonpigArchitecturePageProps {
    title?: string;
    pageTitle?: string;
}


export function MoonpigArchitecturePage(props: MoonpigArchitecturePageProps) {
    const {
        title = 'Moonpig Architecture Overview Diagram',
    } = props;
    const miro_board_url = "https://miro.com/app/live-embed/uXjVMxLAc4A=/?moveToViewport=-1164,-2147,7348,4219&embedId=384595141462";

    const classes = useStyles();

    return (
        <Page themeId="tool">
            <Header title={title}> 
            <SupportButton />
            </Header>
            <Content className={classes.content}>
                <iframe className={classes.iframe} src={miro_board_url}></iframe>
            </Content>
        </Page>
    )
}