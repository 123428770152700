import React from "react";
import { Grid } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Content, Header, Page, Progress, WarningPanel } from "@backstage/core-components";
import { WelcomeTitle, HeaderWorldClock } from "@backstage/plugin-home";
import { EntityGroupProfileCard, MembersListCard, OwnershipCard } from "@backstage/plugin-org";
import { EntityLinksCard } from "@backstage/plugin-catalog";
import { AsyncEntityProvider, useAsyncEntity } from "@backstage/plugin-catalog-react";
import { useGroupEntityFromUser } from "./useEntityFromUser";

export const HomePageContainer = () => {
    return (
        <AsyncEntityProvider {...useGroupEntityFromUser()}>
            <HomePage />
        </AsyncEntityProvider>
    );
}

export const HomePage = () => {
    const entity = useAsyncEntity();
    
    return (
        <Page themeId="home">            
            <Header title={<WelcomeTitle language={["English"]}></WelcomeTitle>} pageTitleOverride="Home">
                <HeaderWorldClock clockConfigs={[{label: "London", timeZone: "Europe/London"}]}/>
            </Header>        
            <Content>                         
                {entity.loading && <Progress/>}  
                {entity.error && <Alert severity="error">{entity.error.toString()}</Alert>}
                {!entity.loading && !entity.error && !entity.entity && (
                    <WarningPanel title="Entity not found">
                        Catalog entity not found.
                    </WarningPanel>
                )}
                {entity.entity && (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <EntityGroupProfileCard variant="gridItem" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <OwnershipCard variant="gridItem" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MembersListCard />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <EntityLinksCard />
                        </Grid>
                    </Grid>
                )}
            </Content>
        </Page>    
    );
}